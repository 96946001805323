/*----------訂閱制頁面----------*/
let Subscription = [
  /*---課程 Start---*/
  {
    path: '/LessonDetailSubscription',
    name: 'LessonDetailSubscription',
    component: () => import('@/views/php/Subscription/LessonDetailSubscription.vue')
  },
  {
    path: '/VideoPlayerSubscription',
    name: 'VideoPlayerSubscription',
    component: () => import('@/views/php/Subscription/VideoPlayerSubscription.vue')
  },
  /*---課程 Start---*/
  /*---電子書 Start---*/
  {
    path: '/EbookLessonSubscription',
    name: 'EbookLessonSubscription',
    component: () => import('@/views/php/Subscription/EbookLessonSubscription.vue')
  },
  {
    path: '/EbookKnowledgeArchitectureSubscription',
    name: 'EbookKnowledgeArchitectureSubscription',
    component: () => import('@/views/php/Subscription/EbookKnowledgeArchitectureSubscription.vue')
  },
  {
    // 前台閱讀
    path: '/EBookClientReviewSubscription',
    name: 'EBookClientReviewSubscription',
    component: () => import('@/views/php/Subscription/EBookClientReviewSubscription.vue')
  },
  /*---電子書 end---*/
  /*---e評量 Start---*/
  { // 題庫
    path: '/ExamRangeSubscription',
    name: 'ExamRangeSubscription',
    component: () => import('@/views/php/Subscription/ExamRangeSubscription.vue')
  },
  { // 難易度參數設定
    path: '/ExamPaperSettingSubscription',
    name: 'ExamPaperSettingSubscription',
    component: () => import('@/views/php/Subscription/ExamPaperSettingSubscription.vue')
  },
  { // 作答畫面
    path: '/StartExamSubscription',
    name: 'StartExamSubscription',
    component: () => import('@/views/php/Subscription/StartExamSubscription.vue')
  },
  {
    // 成績單
    path: '/UserExamPaperSubscription',
    name: 'UserExamPaperSubscription',
    component: () => import('@/views/php/Subscription/UserExamPaperSubscription.vue')
  },
  {
    // 單題測驗
    path: '/SubscribeSingleExam',
    name: 'SubscribeSingleExam',
    component: () => import('@/views/php/Subscription/SubscribeSingleExam.vue')
  },
  /*---e評量 end---*/
  /*---解惑平台 Start---*/
  {
    // 解惑平台
    path: '/QuestionListSubscription',
    name: 'QuestionListSubscription',
    component: () => import('@/views/php/Subscription/QuestionListSubscription.vue')
  },
  /*---解惑平台 end---*/
  /*---學習計畫 Start---*/
  {
    // 學習計畫(周)
    path: '/ArrangePlan',
    name: 'ArrangePlan',
    component: () => import('@/views/php/Subscription/ArrangePlan.vue')
  },
  {
    // 學習計畫(月)
    path: '/ArrangeMonthPlan',
    name: 'ArrangeMonthPlan',
    component: () => import('@/views/php/Subscription/ArrangeMonthPlan.vue')
  },
  {
    // 新增學習計畫
    path: '/AddArrangePlan',
    name: 'AddArrangePlan',
    component: () => import('@/views/php/Subscription/AddArrangePlan.vue')
  },
  {
    // 批量新增學習計畫
    path: '/AddMultiArrangePlan',
    name: 'AddMultiArrangePlan',
    component: () => import('@/views/php/Subscription/AddMultiArrangePlan.vue')
  },
  /*---學習計畫 end---*/
  /*---追蹤收藏 Start---*/
  {
    // 追蹤收藏
    path: '/MyCollectSubscription',
    name: 'MyCollectSubscription',
    component: () => import('@/views/php/Subscription/MyCollectSubscription.vue')
  },
  /*---追蹤收藏 end---*/
  /*---通知提醒 Start---*/
  {
    path: '/MessageListSubscription',
    name: 'MessageListSubscription',
    component: () => import('@/views/php/Subscription/MessageListSubscription.vue')
  },
  {
    path: '/MessageDetailSubscription',
    name: 'MessageDetailSubscription',
    component: () => import('@/views/php/Subscription/MessageDetailSubscription.vue')
  },
  /*---通知提醒 end---*/
  /*---學習紀錄 Start---*/
  {
    // 課程
    path: '/LearnHistorySubscription',
    name: 'LearnHistorySubscription',
    component: () => import('@/views/php/Subscription/LearnHistorySubscription.vue')
  },
  {
    // 電子書
    path: '/LearnHistoryEbookSubscription',
    name: 'LearnHistoryEbookSubscription',
    component: () => import('@/views/php/Subscription/LearnHistoryEbookSubscription.vue')
  },
  // {
  //   // e評量
  //   path: '/LearnHistoryUserExamPaperSubscription',
  //   name: 'LearnHistoryUserExamPaperSubscription',
  //   component: () => import('@/views/php/Subscription/LearnHistoryUserExamPaperSubscription.vue')
  // },
  /*---學習紀錄 end---*/
];
    
export default Subscription;